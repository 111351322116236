import React, { useEffect, useState } from "react";
import {
  UserGroupIcon as Icon,
  CalendarIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { courses } from "../data/courses";
import { handleScroll, isValidDate, isValidTime } from "../utils";
import { useLocation } from "react-router-dom";

export default function Courses() {
  const location = useLocation();
  const [showPastCourses, setShowPastCourses] = useState(false);

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      handleScroll(location.state.scrollTo);
    }
  }, [location]);

  const fourWeeksAgo = new Date();
  fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 1);

  // Validate date and time formats
  courses.forEach((course) => {
    if (!isValidDate(course.date)) {
      throw new Error(`Invalid date format for course: ${course.name}`);
    }
    if (course.time && !isValidTime(course.time)) {
      throw new Error(`Invalid time format for course: ${course.name}`);
    }
  });

  // Filter and sort courses
  const sortedCourses = courses
    .filter((course) => new Date(course.date) > fourWeeksAgo)
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const pastCourses = courses
    .filter((course) => new Date(course.date) <= fourWeeksAgo)
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const togglePastCourses = () => {
    setShowPastCourses(!showPastCourses);
  };

  return (
    <section id="courses" className="bg-white py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-16">
          <Icon className="w-16 h-16 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-6">Courses</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-left">
            As a vital part of TU Delft, we are committed to supporting the
            growth and development of our university staff and researchers. Our
            professional development courses are designed to equip you with
            essential skills and knowledge in high-performance computing (HPC),
            programming, machine learning, among other topics. Developed and
            delivered by experts within our university community, these courses
            provide practical training to enhance your effectiveness in research
            and innovation.
          </p>
        </div>
        {sortedCourses.length > 0 ? (
          <div className="flex flex-wrap -m-4 justify-center items-center">
            {sortedCourses.map((course, index) => (
              <div
                key={index}
                className="px-10 py-7 md:flex md:items-center bg-gray-100 hover:shadow-lg rounded-lg shadow-md mb-4"
                style={{ width: "100%", maxWidth: "786px", height: "auto" }}
              >
                <a href={course.link}>
                  <div className="flex flex-col justify-center text-left">
                    <h1 className="title-font text-lg font-medium text-gray-700">
                      {course.name}
                    </h1>
                    <div className="flex items-center mb-5 text-sm">
                      <CalendarIcon className="w-5 h-5 mr-2" />
                      <span>{`${format(new Date(course.date), "MMMM d, yyyy")} ${course.time} (CEST)`}</span>
                    </div>
                    <p className="title-font text-gray-700 mb-5">
                      {course.desc}
                    </p>
                    <div className="flex items-center text-gray-700">
                      <MapPinIcon className="w-5 h-5 mr-2" />
                      <span>{course.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">
            <p className="text-lg font-medium text-gray-700">
              No courses scheduled.
            </p>
          </div>
        )}
        <div
          className={`mt-20 overflow-hidden transition-all duration-500 ease-in-out ${showPastCourses ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}
        >
          <div className="max-w-3xl mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Past courses</h2>
            <ul className="space-y-2">
              {pastCourses.map((course, index) => (
                <li key={index}>
                  <a
                    href={course.link}
                    className="flex justify-between items-center hover:bg-gray-100 p-2 rounded transition duration-300"
                  >
                    <span className="font-semibold">{course.name}</span>
                    <span>{format(new Date(course.date), "MMMM d, yyyy")}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="text-center mt-16">
          <button
            onClick={togglePastCourses}
            className="bg-white border-4 border-color-blue hover:bg-gray-200 text-lg font-bold py-3 px-6 mt-5 rounded-full transition duration-300"
          >
            {showPastCourses ? "Hide Past Courses" : "Show Past Courses"}
          </button>
        </div>
      </div>
    </section>
  );
}
