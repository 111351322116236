export const positions = [
];


export const old_positions = [
    {
        name: " Research Software Engineer",
        desc: "We are currently looking for candidates to fill one position as a Research (Software) Engineer. As a Research (Software) Engineer, you will be collaborating with researchers on projects across diverse domains, such as artificial intelligence, intelligent human/agent interactions, bioinformatics, computer graphics and visualizations, software engineering, data management and engineering, information systems, cyber security, high performance computing and embedded systems.",
        link: "https://careers.tudelft.nl/job/Delft-Research-Software-Engineer-2628-CD/811843002/"
    }

];